import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Iframe from './Iframe';

import { closePopup } from '../actions/popup';
import { BASE_ZINDEX } from '../popup-factory';
import { getAbsoluteUrl } from '../utils';

class EmailMessagePopup extends Component {

  render() {
    const { index, message, onClosePopup, displayIndex } = this.props;
    const { subject, email } = message || {};
    const {
      sender,
      email_from,
      email_to_recipients,
      email_cc_recipients,
      email_bcc_recipients,
      email_body_html,
      email_body_text,
      email_form_html,
      date_created,
      receipt_requested,
      attachments,
    } = email || {};

    return <div id="email-modal" className="reveal large"
      style={{ display: 'block', zIndex: BASE_ZINDEX + displayIndex}}
      aria-labelledby="modalTitle" aria-hidden="true" role="dialog"
    >
      <div className="row">
        <div className="small-12 columns">
          <h3 id="modalTitle">View Email</h3>
        </div>
      </div>
      <div className="row popup-content">
        <div className="row small-12 columns small-collapse">
          <label className="small-3 columns">From: </label>
          <div className="small-9 columns">
            {(sender && (sender.contact_email == email.email_from))
                // sender.contact_email is deprecate, email.email_from is the real "from"
                ? `${sender.contact_first_name} ${sender.contact_last_name} [${email.email_from}]`
                : (email.email_from ||  `${sender.contact_first_name} ${sender.contact_last_name} [${sender.contact_email}]`)
            }
          </div>
        </div>
        <div className="row small-12 columns small-collapse">
          <label className="small-3 columns">To: </label>
          <div className="small-9 columns">{ email_to_recipients }</div>
        </div>
        {!subject ? null : <div className="row small-12 columns small-collapse">
          <label className="small-3 columns">Subject: </label>
          <div className="small-9 columns">{ subject }</div>
        </div>}
        <div className="row small-12 columns small-collapse">
          <label className="small-3 columns">Date Sent: </label>
          <div className="small-9 columns">
            {moment(date_created).format('MMMM D, YYYY h:mm A')}
          </div>
        </div>
        {!email_cc_recipients ? null : <div className="row small-12 columns small-collapse">
          <label className="small-3 columns">CC: </label>
          <div className="small-9 columns">{ email_cc_recipients }</div>
        </div>}
        <div className="row small-12 columns small-collapse">
          <label className="small-3 columns">Request Read Receipt: </label>
          <div className="small-9 columns">{
            receipt_requested == 1 ? 'Yes' : 'No'
          }</div>
        </div>

        {!email_body_html && !email_body_text ? null : <div className="small-12 columns">
          {email_body_html ?
            <Iframe srcdoc={email_body_html}/> :
            <div style={{ margin: 10 }}>
              {email_body_text.split('\n').map((line) => <div>{line}</div>)}
            </div>}
        </div>}
        {(attachments || []).length === 0 ? null : <div className="small-12 columns">
          <label>Attachments: </label>
          {attachments.map(({
            file_id, file_display_name, file_name_original, file_size, s3
          }, i) => {
            return <a key={i} style={{ display: 'block' }}
              href={+s3 === 1
                ? `https://commonsku-attachments.commonsku.com/${file_id}`
                : getAbsoluteUrl(file_name_original)
              } target="_blank"
            >
              {file_display_name} ({(file_size / 1000).toFixed(2)}k)
            </a>;
          })}
        </div>}

        {!email_form_html ? null: <div className="small-12 columns">
          <label>Attached form: </label>
          <Iframe srcdoc={email_form_html}/>
        </div>}
      </div>
      <button className="close-button" aria-label="Close modal" type="button"
        onClick={e => {e.preventDefault(); onClosePopup();}}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>;
  }
}

const ConnectedEmailMessagePopup = connect(
  (state) => {
    return {};
  },
  (dispatch, ownProps) => {
    return {
      onClosePopup: () => {
        dispatch(closePopup());
      },
    };
  }
)(EmailMessagePopup);

export default ConnectedEmailMessagePopup;

